/* stylelint-disable scss/at-import-partial-extension */
@import '../../../../geely/src/style/abstract/variables';
@import '../../../../geely/src/style/abstract/media';
@import '../../../../geely/src/style/abstract/button';
@import '../../../../geely/src/style/abstract/loader';
@import '../../../../geely/src/style/abstract/icons';
@import '../../../../geely/src/style/abstract/parts';
@import '../../../../geely/src/style/abstract/variables.override';
@import '../../../../geely/src/style/abstract/button.override';
@import '../../../../geely/src/style/abstract/media.override';
@import '../../../../geely/src/style/abstract/parts.override';
@import '../../../../geely/src/style/abstract/icons.override';

:root {
    --my-account-tab-list-background-color: var(--surface-color);
    --my-account-tab-list-stroke-color: var(--stroke-color);
}

.MyAccountTabList {
    background-color: var(--my-account-tab-list-background-color);
    border-inline-start: 0;

    &-ExpandableContentContent {
        padding: 0;
        border-block-end: 1px solid var(--my-account-tab-list-stroke-color);

        @include mobile {
            position: absolute;
        }

        &_isContentExpanded {
            @include mobile {
                width: 100%;
                max-height: unset;
            }
        }

        @include desktop {
            border-inline: none;
            border-block-end: none;
        }
    }

    &-ExpandableContentButton {
        padding: 16px 28px 16px 16px;
    }

    &-Backdrop {
        display: none;
        position: absolute;

        &_isVisible {
            @include mobile {
                width: 100%;
                z-index: 11;
                display: block;
                height: 100%;
                max-height: unset;
                background-color: #00000099;
            }
        }
    }

    @include desktop {
        background-color: var(--my-account-tab-list-background-color);
        border-inline-end: 1px solid var(--my-account-tab-list-stroke-color);
        padding: 0;
        border-block: none;
    }

    @include mobile {
        z-index: 12;
    }
}
