/* stylelint-disable scss/at-import-partial-extension */
@import '../../../../geely/src/style/abstract/variables';
@import '../../../../geely/src/style/abstract/media';
@import '../../../../geely/src/style/abstract/button';
@import '../../../../geely/src/style/abstract/loader';
@import '../../../../geely/src/style/abstract/icons';
@import '../../../../geely/src/style/abstract/parts';
@import '../../../../geely/src/style/abstract/variables.override';
@import '../../../../geely/src/style/abstract/button.override';
@import '../../../../geely/src/style/abstract/media.override';
@import '../../../../geely/src/style/abstract/parts.override';
@import '../../../../geely/src/style/abstract/icons.override';

.MyAccountLoginPopup {
    @include desktop {
        background-color: rgba(0,0,0,0.7);
    }

    &_isVisible {
        inset-block-start: 0;
        height: 100%;
    }

    .ContentWrapper {
        padding: initial;
        min-height: initial;
    }

    .Popup {
        &-ChildrenWrapper {
            padding: 32px 16px;
            margin: 68px 0 0;

            @include desktop {
                padding: 32px 48px;
                height: calc(100% - 76px);
                margin: initial;
                max-height: initial;
            }

            @include mobile {
                margin: 0;
            }
        }

        &-Header {
            display: block;
            position: fixed;
            z-index: 1;
            width: 100%;
            border-bottom: 1px;
            border-style: solid;
            border-color: var(--stroke-color);
            background-color: var(--background-color);
            inset-block-start: 0;

            @include desktop {
                position: relative;
                padding: 0 55px;
            }
        }

        &-HeaderWrapper {
            height: 67px;
            display: flex;
            align-items: center;

            @include desktop {
                height: 75px;
            }
        }

        &-CloseBtn {
            inset-inline-end: 19px;

            @include desktop {
                inset-inline-end: -24px;
            }
        }

        &-Content {
            margin: 68px 0 0;

            @include desktop {
                min-width: 656px;
                height: 600px;
                margin: initial;
            }
        }

        &-Heading {
            font-weight: 400;
            font-size: 20px;

            @include mobile {
                display: none;
            }
        }
    }

    .MyAccountOverlay {
        &-SignInButton {
            margin: 40px 0 0;
        }

        &-Buttons {
            margin: 16px 0 48px;
        }
    }

    .LoginAccount {
        &-SaveForgotWrapper {
            margin: 0 0 12px;
        }

        &-CreateAccountButton {
            margin: 12px 0 0;
        }

        &-ContentWrapper {
            position: relative;
        }

        .Form {
            margin: 0;
        }

        .HeaderText {
            margin: 0 0 12px;
        }

        .Field {
            margin: 0 0 10px;
        }

        .RequiredMark {
            display: flex;
            position: absolute;
            inset-inline-end: 0;
            inset-block-start: 0;

            p, span {
                padding: 0;
                margin: 0;
            }
        }

        .MyAccountOverlay-SignInButton {
            margin: 12px 0;
        }
    }
}
