@mixin scrollbar($widthMobile:  0px, $widthDesktop: 8px) {
    &::-webkit-scrollbar {
        width: $widthMobile;

        @include desktop {
            width: $widthDesktop;
        }
    }

    &::-webkit-scrollbar-thumb {
        background: linear-gradient(90deg, var(--scroll-thumb-color) 50%, rgba(0,0,0,0) 50%);
        border-radius: 6px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }
}

@mixin linkWithArrow {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    transform: translateY(3px);
    margin: 0;
    margin-inline-start: 10px;

    &::after {
        content: '';
        height: 6px;
        width: 6px;
        border: 2px solid var(--primary-base-color);
        border-top: initial;
        border-left: initial;
        transform: rotate(-45deg);
        margin: 0;
        margin-block-end: 4px;
        margin-inline-start: 7px;

        &:dir(rtl) {
            transform: rotate(135deg);
        }
    }

    &:hover::after {
        border-color: var(--link-hover);
    }
}

