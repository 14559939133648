/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

.LoginAccount {
    &-InnerWrapper {
        .MyAccountOverlay {
            &-SignInButton {
                text-align: center;

                .Button {
                    font-family: var(--font-family-sans-pro);
                }

                button {
                    @include desktop {
                        width: 35%;
                    }
                }
            }
        }
    }

    &-CreateAccountButton {
        margin: 24px auto 0;

        @include desktop {
            width: 35%;
        }

        &.Button {
            font-family: var(--font-family-sans-pro);
        }
    }

    &-CreateAccount {
        text-align: center;

        h3, p {
            text-align: start;
        }
    }
}
