/* stylelint-disable scss/at-import-partial-extension */
@import '../../../../geely/src/style/abstract/variables';
@import '../../../../geely/src/style/abstract/media';
@import '../../../../geely/src/style/abstract/button';
@import '../../../../geely/src/style/abstract/loader';
@import '../../../../geely/src/style/abstract/icons';
@import '../../../../geely/src/style/abstract/parts';
@import '../../../../geely/src/style/abstract/variables.override';
@import '../../../../geely/src/style/abstract/button.override';
@import '../../../../geely/src/style/abstract/media.override';
@import '../../../../geely/src/style/abstract/parts.override';
@import '../../../../geely/src/style/abstract/icons.override';

.MyAccountInformation {
    &-Submit {
        min-width: 35%;
        margin-block: 26px;

        @include desktop {
            margin-inline-end: auto;
            min-width: 432px;
        }
    }

    &-Wrapper {
        .Form {
            .FieldForm {
                &-Body {
                    display: flex;
                    flex-direction: column;
                }

                &-Section {
                    width: 100%;
                }

                &-SectionWithSpace {
                    @include mobile {
                        margin-block-start: 0;
                    }
                }

                &-Fields {
                    @include desktop {
                        flex-direction: column;
                    }
                }

                &-ActionsWrapper {
                    button {
                        display: block;
                    }
                }

                &-ChangePasswordWrapper {
                    max-width: 560px;
                }
            }

            .Field-Wrapper {
                @include desktop {
                    width: 560px;
                }
            }
        }
    }

    &-Label {
        position: absolute;
        font-size: 14px;

        @include desktop {
            inset-block-start: 92px;
            inset-inline-end: 86px;
        }

        @include mobile {
            inset-block-start: 30px;
            inset-inline-end: 0;
        }

        @include small-mobile {
            inset-block-start: 72px;
            inset-inline-start: 0;
        }

        .Field-Label {
            margin-inline-start: 3px;
        }
    }
}
