/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

.MyAccountLoginPopup {
    .Popup {
        &-Header {
            background-color: var(--color-white);
            border: none;

            @include desktop {
                border-color: var(--stroke-color);
                background-color: var(--background-color);
            }
        }

        &-HeaderWrapper {
            background: none;
        }

        &-Content {
            margin: 67px 0 0;
        }

        &-CloseBtn {
            path {
                stroke: var(--default-primary-black-color);
            }
        }
    }
}
