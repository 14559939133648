/* stylelint-disable scss/at-import-partial-extension */
@import '../../../../geely/src/style/abstract/variables';
@import '../../../../geely/src/style/abstract/media';
@import '../../../../geely/src/style/abstract/button';
@import '../../../../geely/src/style/abstract/loader';
@import '../../../../geely/src/style/abstract/icons';
@import '../../../../geely/src/style/abstract/parts';
@import '../../../../geely/src/style/abstract/variables.override';
@import '../../../../geely/src/style/abstract/button.override';
@import '../../../../geely/src/style/abstract/media.override';
@import '../../../../geely/src/style/abstract/parts.override';
@import '../../../../geely/src/style/abstract/icons.override';

.CreateAccount {
    margin-block-start: var(--header-height);
    
    &-InnerWrapper {
        @include desktop {
            max-width: 884px;
            display: flex;
            flex-direction: column;

            .Loader {
                &-Scale {
                    position: fixed;
                }
            }
        }

        h1 {
            margin-block-end: 8px;
        }

        & > div {
            margin: 0;
        }
    }

    &-CreateAccountWrapper {
        .Field {
            @include desktop {
                margin-block-start: 36px;
            }
        }

        .MyAccountOverlay {
            &-Legend {
                @include desktop {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    grid-column-gap: 20px;
                    justify-content: space-between;
                    align-content: center;
                }
            }

            &-PasswordBlock {
                @include desktop {
                    grid-row: 2;
                    grid-column: 1 / 3;
                    grid-column-gap: 20px;
                }
            }
        }
    }

    &-WrapperHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include desktop {
            align-items: flex-end;
        }

        .RequiredMark {
            display: flex;
            justify-content: space-between;

            p {
                margin-block-end: 0;
                font-size: 14px;
                line-height: 20px;
            }

            span {
                padding-block-end: 0;
            }
        }
    }

    .ContentWrapper {
        padding-block: 24px 48px;
        padding-inline: 0;

        @include desktop {
            padding-block: 60px 108px;
        }

        @include mobile {
            padding-inline: 16px;
        }
    }
}
