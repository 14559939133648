$default-primary-base-color:  #000000;
$default-primary-dark-color: #231815;
$default-primary-light-color: #CBD5DC;
$default-secondary-base-color: #2E2E2E;
$default-secondary-dark-color: #595757;
$default-secondary-light-color: #F4F5F7;

$font-Arial: 'Arial', 'sans-serif';
$font-HyundaiSansHeadKR: 'HyundaiSansHeadKR', sans-serif;
$font-HyundaiSansTextKR: 'HyundaiSansTextKR', sans-serif;
$black: #000;
$leftMenuWidth: 27vw;
$addToCartHeight: 106px;
$footerContentHeightDesktop: 316px;
$footerCopyrightDesktop: 60px;
$footerHeightDesktop: calc(#{$footerContentHeightDesktop} + #{$footerCopyrightDesktop});
$borderRadius: 6px;
$productTitleHeight: 120px;
